'use client'
import {useEffect, useState} from "react";
import {getLastPosts} from "@/app/endpoints/wpEndpoints";

interface ILastPosts {
    link: any;
    h1: string;
    date: string;
}

export default function LastPostsSection() {
    const [isLastPostsSectionOpen, setIsArticlesOpen] = useState(false)
    const [lastPosts, setLastPosts] = useState<ILastPosts[]>([]);

    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'};

    useEffect(() => {
        const getLastPostsFromApi = async () => {
            setLastPosts(await getLastPosts() || []);
        };

        getLastPostsFromApi();
    }, []);

    return (
        <>
            {lastPosts.length > 0 &&
                <div className={'text-center lg:flex lg:flex-col container px-2 lg:px-12 2xl:px-20 items-center gap-4'}>
                    <h2 className={'text-xl md:text-3xl pb-5'}>Articles récents</h2>

                    <div
                        className={'flex lg:flex-wrap lg:justify-center gap-5 overflow-auto lg:overflow-hidden transition-[max-height] ' +
                            (isLastPostsSectionOpen ? 'lg:max-h-[510px] 2xl:max-h-[320px]' : 'max-h-[150px]')}
                    >
                        {lastPosts?.map((lastPosts, index) => {
                            return (
                                <div
                                    key={`lastpost-${index}`}
                                    className={'max-w-1xl'}
                                >
                                    <a
                                        href={lastPosts.link}
                                        className={'w-[220px] h-[150px] rounded-lg border flex flex-col hover:text-blue-500 hover:bg-blue-100 p-5 justify-between'}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: lastPosts.h1.replace(/<[^>]*>/g, '')}} />

                                        <div className={'text-end text-black'}>
                                            {new Date(lastPosts.date).toLocaleDateString('fr-FR')}
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </div>

                    <button
                        className={'lg:flex items-center justify-center rounded-full border w-10 h-10 hidden'}
                        onClick={() => setIsArticlesOpen(!isLastPostsSectionOpen)}
                    >
                        <i className={'fa-solid ' + (isLastPostsSectionOpen ? 'fa-chevron-up' : 'fa-chevron-down')} />
                    </button>
                </div>
            }
        </>
    )
}
