import {
    ICommentsNumber,
    IPostsNumber,
    IWPCommentsPayload,
    IWPContentPayload,
    IWPIdBySlug,
    IWPCardPost,
    TBreadcrumb,
    ICategoryInfosAndPages, IAuthorPage,
} from "@/app/models/wp"
import { notFound } from "next/navigation"
import type { ISchema } from "@/app/models/schema"
import type { IYoastSEOMeta } from "@/app/models/yoast"

export const BASE_API: string = `${process.env.NEXT_PUBLIC_RM_BASE_AUCTOR_API}/api`
export const WP_API: string = `${process.env.NEXT_PUBLIC_RM_WORDPRESS_API}/guide/wp-json/rm-api`
export const YOAST_API: string = `${process.env.NEXT_PUBLIC_RM_WORDPRESS_API}/guide/wp-json/wp/v2`
export const WP_PATH_PREFIX = "/guide"

export const ENDPOINTS = {
    GET_PAGE_CONTENT: `${BASE_API}/wp/page/content/by-id?id=%ID%`,
    GET_POST_CONTENT: `${BASE_API}/wp/post/content/by-id?id=%ID%`,
    GET_PAGE_META: `${BASE_API}/wp/page/meta/by-id?id=%ID%`,
    GET_PAGE_COMMENTS: `${BASE_API}/wp/page/comments/by-id?id=%ID%`,
    POST_NEW_COMMENT: `${BASE_API}/wp/page/comment/for-id`,
    GET_COMMENTS_NUMBER: `${WP_API}/get-total-comments-number?pageId=%ID%`,
    GET_BREADCRUMB_BY_ID: `${WP_API}/get-breadcrumb-by-id?pageId=%ID%`,
    GET_WP_BY_ID: `${WP_API}/get-page-by-id?pageId=%ID%`,
    GET_ID_BY_SLUG: `${WP_API}/get-page-id-by-slug?slug=%SLUG%`,
    GET_YOAST_META_BY_ID: `${WP_API}/get-meta-by-id?pageId=%ID%`,
    GET_YOAST_CATEGORY_META_BY_ID: `${YOAST_API}/categories/%ID%`,
    GET_YOAST_SCHEMA_BY_ID: `${WP_API}/get-schema-by-id?pageId=%ID%`,
    GET_ACTUS: `${WP_API}/get-actus?actualPage=%PAGE%&postsPerPage=%PER_PAGE%`,
    GET_AUTHOR_BY_NAME: `${WP_API}/get-author-by-name?`,
    GET_CATEGORY_INFOS_AND_PAGES: `${WP_API}/get-category-infos-and-pages-list-by-id?id=%CATEGORY_ID%`,
    GET_TOTAL_PAGES_BY_CATEGORY: `${WP_API}/get-total-pages-by-category?category=%CATEGORY%`,
    GET_NEIGHBORS_POSTS: `${WP_API}/get-neighbors-posts?postId=%POST_ID%&postsNumber=%POSTS_NUMBER%`,
    GET_REDIRECTIONS: `${WP_API}/get-wp-redirections`,
    GET_WP_SLUGS: `${WP_API}/slugs`,
    GET_WP_SLUGS_FOR_SITEMAP: `${WP_API}/slugs-for-sitemap`,
    GET_FULL_LINK: `${WP_API}/prettylink?short_url=%SHORT_URL%`,
}

export async function hitWpApi(url: string, cache = true): Promise<any> {
    const cachePolicy = cache ? "force-cache" : "no-store"
    const response = await fetch(url, {
        cache: cachePolicy,
        headers: { "X-Guide-Proxy-Key": process.env.WP_PROXY_KEY || "FUbXPtxQYy9sWp97OGMammOnjiDIfNBe" },
    })

    return response
}

export async function getLastPosts() {
    const url = `${BASE_API}/wp/get-last-posts-heading`
    const response = await hitWpApi(url)

    return response.json()
}

export async function getFulLUrlFromPrettyLink(shortUrl: string): Promise<{ short_url: string; full_url: string }> {
    const url  = ENDPOINTS.GET_FULL_LINK.replace("%SHORT_URL%", shortUrl)
    const response = await hitWpApi(url, false)
    return response.json()
}

export async function getTotalPagesByCategory(category: string, cache = true): Promise<IPostsNumber> {
    const url = ENDPOINTS.GET_TOTAL_PAGES_BY_CATEGORY.replace("%CATEGORY%", String(category))
    const response = await hitWpApi(url, cache)
    if (!response.ok) {
        return notFound()
    }
    return response.json()
}

export async function getCategoryInfosAndPagesById(categoryId: string, cache: boolean = true): Promise<ICategoryInfosAndPages> {
    const url = ENDPOINTS.GET_CATEGORY_INFOS_AND_PAGES.replace('%CATEGORY_ID%', String(categoryId))
    const response = await hitWpApi(url, cache)

    if (!response.ok) {
        return notFound()
    }
    return response.json()
}

export async function getWPIdBySlug(slug: string, cache = true): Promise<IWPIdBySlug> {
    const url = ENDPOINTS.GET_ID_BY_SLUG.replace("%SLUG%", slug)
    const response = await hitWpApi(url, cache)

    if (!response.ok) {
        return notFound()
    }
    return response.json()
}

export async function getWPContent(id: string, cache = true): Promise<IWPContentPayload> {
    const url = ENDPOINTS.GET_WP_BY_ID.replace("%ID%", id)
    const response = await hitWpApi(url, cache)

    if (!response.ok && process.env.APP_ENV === "dev") {
        const err = response.json()
        return err
    }

    if (!response.ok) {
        return notFound()
    }
    return response.json()
}

export async function getWPPageComments(id: string, commentsNumber?: number, cache = true): Promise<IWPCommentsPayload[]> {
    let url = ENDPOINTS.GET_PAGE_COMMENTS.replace("%ID%", id)
    if (commentsNumber) {
        url += `&commentsNumber=${commentsNumber}`
    }
    const response = await hitWpApi(url, cache)

    if (!response.ok) {
        return notFound()
    }

    return response.json()
}

export async function getCommentsNumber(id: string, cache = true): Promise<ICommentsNumber> {
    const url = ENDPOINTS.GET_COMMENTS_NUMBER.replace("%ID%", id)
    const response = await hitWpApi(url, cache)
    return response.json()
}

export async function getNeighborsPosts(wpPageId: string, postsNumber: string, cache = true): Promise<IWPCardPost[]> {
    const url = ENDPOINTS.GET_NEIGHBORS_POSTS.replace("%POST_ID%", wpPageId).replace("%POSTS_NUMBER%", postsNumber)
    const response = await hitWpApi(url, cache)

    if (!response.ok && process.env.APP_ENV === "prod") {
        return notFound()
    }

    return response.json()
}

export async function getActus(actualPage: number, postsPerPage: number, cache = true): Promise<IWPCardPost[]> {
    const url = ENDPOINTS.GET_ACTUS.replace("%PAGE%", String(actualPage)).replace("%PER_PAGE%", String(postsPerPage))
    const response = await hitWpApi(url, cache)

    if (!response.ok && process.env.APP_ENV === "prod") {
        return notFound()
    }

    return response.json()
}

export async function getAuthorByName(firstName?: string, lastName?: string, cache = true): Promise<IAuthorPage> {
    let url = ENDPOINTS.GET_AUTHOR_BY_NAME
    if (firstName) {
        url = url + "first_name=" + firstName
    }

    if (lastName) {
        if (firstName) {
            url = url + "&"
        }

        url = url + "last_name=" + lastName
    }

    const response = await hitWpApi(url, cache)
    if (!response.ok && process.env.APP_ENV === "prod") {
        return notFound()
    }

    return response.json()
}

export async function getNewsSchemaBySlug(slug: string, cache = true): Promise<any> {
    const url = `${BASE_API}/wp/post/news-schema/by-slug?slug=` + slug
    const response = await hitWpApi(url, cache)

    if (!response.ok && process.env.APP_ENV === "prod") {
        return notFound()
    }

    return response.json()
}

export async function getBreadcrumbByID(id: string | number, cache = true): Promise<TBreadcrumb> {
    id = String(id)
    const response = await hitWpApi(ENDPOINTS.GET_BREADCRUMB_BY_ID.replace("%ID%", id), cache)

    if (!response.ok && process.env.APP_ENV === "prod") {
        return notFound()
    }

    return response.json()
}

export async function getYoastMetaByID(id: string, cache = true): Promise<IYoastSEOMeta> {
    const url = ENDPOINTS.GET_YOAST_META_BY_ID.replace("%ID%", id)
    const response = await hitWpApi(url, cache)

    if (!response.ok && process.env.APP_ENV === "prod") {
        return notFound()
    }

    return response.json()
}

export async function getYoastCategoryMetaByID(id: string, cache: boolean = true): Promise<any> {
    const url = ENDPOINTS.GET_YOAST_CATEGORY_META_BY_ID.replace('%ID%', id)
    const response = await hitWpApi(url, cache);

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getYoastSchemaByID(id: string, cache = true): Promise<ISchema> {
    const response = await hitWpApi(ENDPOINTS.GET_YOAST_SCHEMA_BY_ID.replace("%ID%", id), cache)

    if (!response.ok && process.env.APP_ENV === "prod") {
        return notFound()
    }

    return response.json()
}
